import React from 'react';
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-bottom">
          <p className="copyright">
            &copy; {currentYear} L'AVION Express. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
